.b-timeTable-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: #e8eef4;
  height: 42px;
  border: 1px solid #6d9fbc;
  padding: 8px 8px 8px 8px;
  padding-right: 15px;
  margin-bottom: 24px;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #4d4d4d;
  white-space: normal;
  border-radius: 4px;
}
.b-timeTable-header-project {
  flex-grow: 1;
}
.time-table-tr {
  white-space: normal;
  justify-content: left;
  padding: 4px 15px;
  border-right: 1px solid #6d9fbc;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #4d4d4d;
}
.time-table-tr:last-child {
  border-right: 0px;
}
.sticky-header {
  position: sticky;
  padding-top: 24px;
  background: #ffff;
  z-index: 0;
  top: 0;
}
.b-timeTable-header-date {
  flex-grow: 1;
}

.b-timeTable-header-created {
  flex-grow: 1;
}
.b-timeTable-header-user {
  flex-grow: 1;
}

.b-timeTable-header-type {
  flex-grow: 1;
}

.b-timeTable-header-item {
  flex-grow: 2;
}

.b-timeTable-header-comment {
  flex-grow: 3;
}

.b-timeTable-header-status {
  flex-grow: 1;
}
.b-timeTable-header-hours {
  flex-grow: 1;
}
