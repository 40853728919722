.subscriber-card {
  border: 1px solid#6D9FBC;
  border-radius: 4px;
  padding: 4px 6px 4px 6px;
  align-items: center;
  margin-right: 8px;
  height: 45px;
  white-space: nowrap;
}

.subscriber-card_topic {
  padding: 4px 6px;
  border: 1px solid #a5a5a5;
  border-radius: 4px;
  align-items: center;
  margin-right: 8px;
  height: 45px;
  margin-top: 5px;
  width: 150px;
}

.subscriber-card-name {
  margin-right: 3px;
  color: #01629b;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subscriber-card-name_topic {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #a5a5a5;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
}

.subscriber-card-post {
  font-weight: 300;
  font-size: 11px;
  line-height: 11px;
  min-height: 12px;
  color: #4d4d4d;
}

.subscriber-card-delete {
  display: block;
  cursor: pointer;
  height: 14px;
  float: right;
}

.subscriber-card-icon {
  display: none;
}

.subscriber-card-icon_topic {
  filter: invert(0.4) sepia(0) saturate(1) hue-rotate(0deg) brightness(1.2);
}
