.b-popover {
  border-radius: 4px;
  border: 1px solid #6d9fbc;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  width: 100%;
}
.b-popover-Row {
  display: block;
}

.b-popover-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 8px 10px;
  background: #e8eef4;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #6d9fbc;
}
.b-popover-top h3 {
  font-size: 12px;
  line-height: 14px;
}
.b-popover-controls button {
  display: inline-block;
  border: 0;
  margin-left: 14px;
}
.b-popover-content {
  border-radius: 4px;
  padding: 8px;
  background-color: #fff;
}

.b-popover-content .form-control {
  min-height: 32px;
  border: 1px solid #6d9fbc !important;
  border-radius: 4px;
}

.b-popover-content .form-label {
  font-weight: 300;
  font-size: 11px;
  line-height: 11px;
  color: #4d4d4d;
}

.b-popover-content .more-fields {
  display: inline-block;
  margin: 10px 0 10px 10px;
  cursor: pointer;
}
.b-popover-content .more-fields img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
}

.b-popover-content .more-fields p {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #01629b;
}

.b-popover-controls .btn-cancel {
  background: #fff;
  border: 1px solid #6d9fbc;
  height: 30px;
  align-items: center;
  justify-content: center;
  color: #1689c9;
}

.b-popover-controls .btn-cancel:hover {
  border-color: #0a58ca;
}
