.b-timereport-select {
  width: 180px;
}
.b-timereport-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 16px 10px;
  position: sticky;
  top: 0px;
  background-color: #fff;
  z-index: 1;
  border: 1px solid #89add5;
  border-radius: 4px;
}
.filter-popups {
  display: flex;
  align-items: center;
}
.filter-popups > div {
  margin: 0 10px;
}
.b-timereport-filters .b-toggle .form-check {
  margin-bottom: 0;
}
.download-btn .text {
  vertical-align: middle;
}
.download-btn .icon {
  padding-left: 30px;
  width: 50px;
}
