.changePriority {
  display: flex;
  flex-direction: row;
  align-items: center;

  .priority {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: #e9e9e9;
  }

  .btnEdit {
    margin-left: 4px;
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    img {
      display: block;
      width: 16px;
      height: 16px;
    }
  }
}
