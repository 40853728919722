.table {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #6d9fbc;

  th {
    width: 100px;
    background-color: #e8eef4;
    position: sticky;
    top: 60px; /* высота хедера */
    z-index: 1;
  }
  th:first-child {
    width: auto;
  }

  tbody {
    border-top: 0 !important;
  }

  td {
    position: relative;

    img {
      display: none;
      position: absolute;
      top: 6px;
      right: 6px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    &:hover img {
      display: block;
    }
  }
}

.errorMessage {
  text-align: center;
  font-size: 18px;
  color: red;
}
