.totalwrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 34px;
  margin-bottom: 24px;
  font-weight: 600;
}

.totalwrapper_empty {
  display: none;
}