.fileInputHtml {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.files {
  & > label {
    font-weight: 300;
    font-size: 11px;
    line-height: 11px;
    color: #4d4d4d;
    margin-bottom: 0.3rem;
  }

  .content {
    background-color: #e7eef2;
    border-radius: 2px;

    .filesInput {
      padding: 4px 4px 6px 4px;

      label {
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
        line-height: 12px;
        font-weight: 500;
        color: #01629b;
        margin-right: 16px;
        cursor: pointer;

        img {
          display: inline-block;
          vertical-align: middle;
          margin-right: 6px;
        }
        p {
          display: inline-block;
          vertical-align: middle;
        }
      }

      .maxSizeMessage {
        display: inline-block;
        vertical-align: middle;
        font-size: 11px;
        line-height: 11px;
        font-weight: 300;
        color: #4d4d4d;
      }
    }

    .filesList {
      border-top: 1px solid #afd2e7;
      margin-bottom: 10px;
    }
  }
}
