.changeProjectDescription {
  .text {
    display: flex;
    align-items: center;
    .btnEdit {
      display: inline-block;
      margin-left: 4px;
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }

      img {
        display: block;
        width: 16px;
        height: 16px;
      }
    }
    .textEmpty {
      ~ .btnEdit {
        margin-left: 0;
      }
    }
  }
  .editing {
    .input {
      margin-bottom: 7px;
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
  }
}
