.table-component {
  width: 100%;
}
.table-component-header {
  display: block;
  margin-bottom: 24px;
  font-size: 20px;
}
.timeTable-string {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #6d9fbc;
  border-left: 1px solid #6d9fbc;
  border-right: 1px solid #6d9fbc;
  white-space: normal;
  align-items: flex-start;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #4d4d4d;
}
.timeTable-string:last-child {
  border-radius: 0 0 4px 4px;
}
.timeTable-string:first-of-type {
  border-top: 1px solid #6d9fbc;
  border-radius: 4px 4px 0 0;
}
.timeTable {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  flex-basis: 0;
  padding: 4px 15px;
}
.timeTable-date {
  flex-grow: 1;
}

.timeTable-created {
  flex-grow: 1;
}
.timeTable-user {
  flex-grow: 1;
}

.timeTable-type {
  flex-grow: 1;
}

.timeTable-item {
  flex-grow: 2;
}

.timeTable-comment {
  flex-grow: 3;
  word-break: break-word;
}

.timeTable-status {
  flex-grow: 1;
}
.timeTable-hours {
  flex-grow: 1;
}
.timeTable-project {
  flex-grow: 1;
}

.timeReport-container {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  margin: 10px 0 30px;
}
