.pagination {
  flex-wrap: wrap;

  :global(.page-link) {
    padding: 6px 10px;
    color: #000;
    border-color: #6d9fbc;
  }

  :global(.page-item.active .page-link),
  :global(.page-item:hover .page-link) {
    color: #fff;
    background-color: #1689c9;
    border-color: #1689c9;
  }

  :global(.page-item.active .page-link) {
    pointer-events: none;
  }

  :global(.page-item.disabled .page-link) {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
    border-color: #dee2e6;
  }
}
