.timeTab {
  display: block;
  width: 100%;
}

.timeTab-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  margin: 20px 0;
}
