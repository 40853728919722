.container {
  .icon {
    height: 24px;
    width: 24px;
    opacity: 0.5;

    &.active {
      opacity: 1;
      cursor: pointer;
    }
  }
}

.popover {
  width: 600px;
  max-width: 600px;
}

:global(.popover-body) {
  padding: 0;
}
