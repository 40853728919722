.block-content__title {
  color: #4d4d4d;
  font-weight: 300;
  line-height: 10.55px;
}

.block-content__input {
  position: relative;
}

.block-content__search {
  width: 100%;
  background: #ffffff;
  border: 1px solid #6d9fbc;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  padding: 10px;
  margin-bottom: 10px;
}

.block-content__icon {
  height: 20px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 10px;
}

.block-content {
  width: 100%;
  padding: 20px;
}

.block-content__users {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
}

.block-content__users .subscriber-card-delete {
  display: none;
}

.block-content__users .subscriber-card-icon {
  cursor: pointer;
  float: right;
  display: block;
  height: 14px;
}