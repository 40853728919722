.b-kanban-card {
  border: 1px solid #6d9fbc;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
}

.b-kanban-card:last-child {
  margin-bottom: 20px;
}

.b-kanban-card-chain {
  margin-bottom: 6px;
}

.b-kanban-card-chain-item {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #01629b;
  margin-bottom: 4px;
}

.b-kanban-card-chain-item img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}
.b-kanban-card-chain-item a {
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  color: #01629b;
  font-weight: 500;
  padding-bottom: 1px;
}

.b-kanban-card-string {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.b-kanban-card-due {
  position: relative;
  color: #4d4d4d;
  font-size: 10px;
  line-height: 10px;
}

.b-kanban-card-assignee {
  color: #01629b;
  font-weight: 500;
}
