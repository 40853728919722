.projectCard {
  border: 1px solid #6d9fbc;
  border-radius: 4px;
  .header {
    align-items: center;
    border-radius: 4px 4px 0 0;
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    background-color: #e8eef4;
    border-bottom: 1px solid #6d9fbc;
    .headerName {
      color: #4d4d4d;
      font-weight: 500;
    }
    .headerIcons {
      display: flex;
    }
  }
  .cardBody {
    padding: 0 10px 15px 10px;
    .bodyParam {
      padding-top: 18px;
      color: #4d4d4d;
      font-weight: 300;
      font-size: 11px;
      line-height: 11px;
    }
    .bodyValue {
      padding-top: 8px;
      color: #4d4d4d;
      font-weight: 500;
      white-space: pre-wrap;
      word-break: break-word;
    }
    .bodyValue.status {
      width: 200px;
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      .tag {
        border: 1px solid #1687c9;
        margin-right: 10px;
        color: #1687c9;
        margin-bottom: 10px;
        padding: 5px;
        border-radius: 2px;
      }
    }
    .availableItemTypes {
      margin-right: 22px;
      line-height: 35px;
    }
  }
}
