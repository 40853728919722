.b-due {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.b-due .btn-edit {
  cursor: pointer;
  margin-left: 5px;
  opacity: 0.5;
}

.b-due .btn-edit:hover {
  opacity: 1;
}

.datepicker-due {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 10;
}

.datepicker-due .react-datepicker__navigation {
  top: -2px;
}
