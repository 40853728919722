.container {
  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .error {
    border-color: #dc3545 !important;
  }
}
