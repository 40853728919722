.description {
  .key {
    display: inline-block;
    font-weight: 700;
  }
  .key:first-letter {
    text-transform: uppercase;
  }
}

.author {
  text-decoration: underline;
}
