.container {
  .billing {
    font-weight: 300;
    font-size: 11px;
    line-height: 11px;
    color: #4d4d4d;
    margin-bottom: 0.5rem;
  }

  :global(.form-control) {
    padding: 0.55rem 0.75rem;
    background-color: #e7eef2;
    border: 1px solid #e7eef2;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .availableItemTypes {
    margin-right: 22px;
  }

  :global(.btn-group > .btn-check:checked + .btn) {
    z-index: 0;
  }
}
