.container {
  .buttons {
    display: flex;
    gap: 6px;
    margin-bottom: 20px;
  }
  .filters {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 20px;
  }
  .pagination {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    margin-top: 10px;
  }
}
