.projectTable {
  margin-top: 20px;

  table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    display: flex;
    flex-direction: column;

    thead {
      tr {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        background-color: #e8eef4;
        height: 44px;
        border: 1px solid #6d9fbc;
        padding: 0 0 0 15px;
        margin-bottom: 24px;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        white-space: normal;
        border-radius: 4px;
      }
      th {
        display: flex;
        align-items: center;
        color: grey;
        div {
          width: 100%;
          img {
            margin-left: 5px;
          }
        }
      }
      th:not(:last-of-type) {
        div {
          border-right: 1px solid #6d9fbc;
        }
      }
      .sortableColumn {
        cursor: pointer;
        span {
          color: #000;
        }
      }
    }

    tbody {
      tr:first-of-type {
        border-top: 1px solid #6d9fbc;
        border-radius: 4px 4px 0 0;
      }
      tr {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #6d9fbc;
        border-left: 1px solid #6d9fbc;
        border-right: 1px solid #6d9fbc;
        white-space: normal;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: #4d4d4d;
        padding: 0 0px 0px 15px;
      }
      td:not(:first-of-type) {
        padding: 10px;
      }
    }

    th:not(:first-of-type) {
      padding: 10px 0px 10px 10px;
    }

    td:nth-child(1),
    th:nth-child(1) {
      width: 28px;
    }

    td:nth-child(2),
    th:nth-child(2) {
      width: 4%;
    }

    td:nth-child(3),
    th:nth-child(3) {
      width: 10%;
    }

    td:nth-child(4),
    th:nth-child(4) {
      width: 30%;
    }

    td:nth-child(5),
    th:nth-child(5) {
      width: 8%;
    }

    td:nth-child(6),
    th:nth-child(6) {
      width: 12%;
    }

    td:nth-child(7),
    th:nth-child(7) {
      width: 35%;
    }
  }
}
