.popoverlink {
  display: inline-block;
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
  border: 1px solid;
  border-color: #6d9fbc;
  border-radius: 4px;
  width: 300px;
  height: auto;
}
.popoverlink > .popover-arrow::after {
  border-bottom-color: #e8eef4;
  left: -10px;
}
.popoverlink > .popover-arrow::before {
  left: -10px;
}

.linkpopover-wrapper {
  padding: 0;
  font-size: 12px;
  width: 100%;
  height: auto;
}
.linkpopover-header {
  background-color: #e8eef4;
  border-bottom: 1px solid #6d9fbc;
  padding: 4px;
  border-radius: 4px 4px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.linkpopover-warning {
  color: #1687c9;
}
.linkpopover-content {
  padding: 4px;
  display: flex;
  flex-direction: column;
  user-select: all;
}
.linkpopover-button {
  padding: 6px;
  width: 50%;
}
