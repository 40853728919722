.container {
  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  :global(.btn-group > .btn-check:checked + .btn) {
    z-index: 0;
  }
}
