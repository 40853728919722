.projectPage {
  .breadcrumbs {
    margin-bottom: 10px;
    padding-left: 0;

    li {
      display: inline-block;
      vertical-align: top;
      color: #01629b;

      a {
        color: #01629b;
        text-decoration: none;
      }
      &:not(:last-child) a {
        font-weight: 500;
      }
      &:not(:first-child):before {
        content: '/';
        margin: 0 4px;
      }
    }
  }

  .projectDetail {
    display: flex;

    .projectForm {
      width: 30%;
      margin-right: 24px;
    }
    .detailProjectTabs {
      width: 70%;
    }
  }

  @media screen and (max-width: 1400px) {
    .projectDetail {
      flex-direction: column;

      .projectForm {
        width: 100%;
        margin-right: 0;
      }
      .detailProjectTabs {
        width: 100%;
        margin-top: 25px;
      }
    }
  }
}

.error {
  text-align: center;

  .errorMessage {
    font-size: 18px;
    color: red;
    margin-bottom: 10px;
  }
}
