.sticky {
  position: sticky;
}

.page {
  display: flex;
  height: 100%;

  .content {
    width: 100%;

    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 12px;
      top: 0px;
      z-index: 110;
      background-color: #fff;

      .logo {
        margin-right: 16px;
      }
    }

    .workspace {
      padding: 10px 12px 0;
    }
  }
}
