.b-kanban-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.b-kanban-filter-tasks {
  font-size: 12px;
  line-height: 12px;
}
.b-kanban-filter-tasks-text {
  display: inline-block;
  font-weight: 500;
  margin-right: 24px;
}
.b-kanban-filter-tasks-item {
  display: inline-block;
  font-weight: 300;
  margin-right: 24px;
}
.b-kanban-filter-tasks-item span {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: #1687c9;
}
.b-kanban-filter-show {
  padding-left: 245px;
  flex: 1;
  display: flex;
  align-items: center;
}
.b-kanban-toggle {
  width: 160px;
}
