.tags-indicator {
  width: 20px;
  height: 20px;
}

.parent-item-cont {
  position: relative;
}
.parent-item-cont .show-innactive-button {
  position: absolute;
  top: 4px;
  right: 0;
  font-size: 11px;
  line-height: 11px;
  font-weight: 500;
  color: #01629b;
  text-decoration: none;
}

.b-popover.create .b-text-editor {
  margin-bottom: 8px;
}

.b-popover .hidden-extra-fields {
  visibility: hidden;
  opacity: 0;
  height: 0;
}

@media screen and (max-width: 1400px) {
  .b-popover-Row {
    display: flex;
  }
  .col {
    width: 100%;
  }
  .b-text-editor {
    width: 100%;
  }
}

@media screen and (min-width: 1400) {
  .col {
    width: 100%;
  }
}
