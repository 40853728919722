.preview-file {
  position: relative;
}

.preview-file__name {
  display: inline-block;
  color: #01629b;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  word-break: break-word;
}

.preview-file__img {
  position: absolute;
  max-width: 700px;
  max-height: 700px;
  left: 0;
  top: 10px;
  z-index: 1;
  cursor: default;
  padding-top: 10px;
}

.preview-file__pdf {
  position: absolute;
  width: 700px;
  height: 700px;
  left: 0;
  top: 10px;
  z-index: 1;
  cursor: default;
  padding-top: 10px;
}
