.container {
  position: relative;
  display: flex;
  gap: 8px;
  margin-right: 20px;

  .popoverCont {
    position: absolute;
    top: calc(100% + 3px);
    left: 0;
    z-index: 10;

    .popoverTask {
      width: 800px;
    }
  }
}
