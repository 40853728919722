.form {
  .note {
    font-size: 12px;
    line-height: 14px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #6d9fbc;
    background-color: #e8eef4;
    border-radius: 4px 4px 0 0;

    .left {
      margin-right: 20px;

      .title {
        font-size: 14px;
        line-height: 14px;
        font-weight: 700;
        margin-bottom: 6px;
      }
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      button {
        margin-left: 10px;
      }
    }
  }

  .content {
    padding: 10px;
  }
}
