@import '../../../utils/styles/variables.scss';

.activityTab {
  .activityHeader {
    border: 1px solid #6d9fbc;
    width: 100%;
    border-top-right-radius: $border-radius-header;
    padding: 16px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    margin: 20px 0;
  }
}
