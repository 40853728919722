.b-table-companies .tr {
  padding: 8px 0;
}

.b-table-companies .td {
  font-size: 14px;
  line-height: 16px;
}

.b-table-companies-user-padding {
  padding-left: 35px;
}

.b-table-companies-expand {
  margin-right: 6px;
  cursor: pointer;
}

.b-table-companies-edit {
  margin-left: 10px;
  cursor: pointer;
}
