@import '../../../../utils/styles/variables.scss';

.greycorner {
  width: 100%;
  display: flex;
  height: auto;
  align-items: center;
  background-color: #e8eef4;
}

.wrapper {
  border-top-right-radius: $border-radius-header;
  border: 1px solid #6d9fbc;
  background-color: #fff;
  width: 100%;
}

.filter.wrapper {
  width: 100%;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  background-color: #fff;
}

.filterbuttons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.filterbuttons > div {
  display: flex;
  align-items: center;
}

.filter .add {
  margin-right: 18px;
}

.filter .add img {
  width: 22px;
  height: 22px;
  vertical-align: middle;
  cursor: pointer;
}

.filter .edit {
  width: 22px;
  height: 22px;
  margin-right: 18px;
  opacity: 0.5;
}

.filter .delete {
  width: 22px;
  height: 22px;
  opacity: 0.5;
  margin-right: 40px;
}

.filter .edit_active,
.filter .delete_active {
  cursor: pointer;
  opacity: 1;
}

.filterbuttons img {
  width: 22px;
  height: 22px;
  margin-right: 12px;
}

.editbuttons {
  display: flex;
}
