.breadcrumbs {
  margin-bottom: 10px;
  padding-left: 0;

  li {
    display: inline-block;
    vertical-align: top;
    color: #01629b;

    a {
      color: #01629b;
      text-decoration: none;
    }
    &:not(:last-child) a {
      font-weight: 500;
    }
    &:not(:first-child):before {
      content: '/';
      margin: 0 4px;
    }
  }
}

.taskDetail {
  display: flex;

  .taskForm {
    width: 30%;
    margin-right: 24px;
  }
  .detailTaskTabs {
    width: 70%;
  }
}

.error {
  text-align: center;

  .errorMessage {
    font-size: 18px;
    color: red;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1400px) {
  .taskDetail {
    flex-direction: column;

    .taskForm {
      width: 100%;
      margin-right: 0;
    }
    .detailTaskTabs {
      width: 100%;
      margin-top: 25px;
    }
  }
}
