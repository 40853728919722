.comment-card {
  border: 1px solid #6d9fbc;
  border-radius: 4px;
  margin-top: 18px;
  padding: 16px;
  display: flex;
}

.comment-card.pinned {
  background-color: #e7eef2;
}

.comment-card.highlight {
  background-color: #e7eef2;
}

.card-person {
  width: 10%;
  min-width: 130px;
}

.card-person .person-name {
  font-weight: 500;
  font-size: 14px;
  color: #01629b;
  margin-top: 4px;
}

.card-person .created {
  font-weight: 300;
  font-size: 12px;
  line-height: 13px;
  color: #4d4d4d;
}

.action-image {
  margin-right: 16px;
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.person-created {
  font-weight: 300;
  font-size: 10px;
  line-height: 10px;
  margin-top: 6px;
}

.action-icons {
  margin-top: 8px;
}

.comment-content {
  width: 90%;
  padding-left: 10px;
  white-space: pre-wrap;
}

.comment-text blockquote {
  margin: 0;
}

.comment-text code {
  display: block;
}

.comment-text > blockquote,
.comment-text code {
  padding: 10px;
  background-color: #e8eef4;
  border-radius: 4px;
  margin: 5px 0 10px;
  word-break: break-word;
}

.comment-files {
  margin-top: 30px;
}

.comment-card__restore {
  margin-left: 45px;
  background-color: #fff;
  color: #1689c9;
}

.comment-card_deleted {
  align-items: center;
  background: rgb(192, 192, 192);
}
