.sticky-show > p {
  color: #1687c9;
  display: inline-block;
  font-weight: 500;
}

.sticky-show {
  margin-top: 24px;
  display: inline-block;
  cursor: pointer;
}

.sticky-show-image {
  margin-left: 5px;
  transform: rotate(90deg);
}

.sticky-show-image.rotate {
  transform: rotate(270deg);
}

.sticky-show > * {
  margin-right: 5px;
}

.comment-editor {
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #6d9fbc;
  border-radius: 4px;
}

.comment-editor-pinnedcheck {
  display: flex;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  align-items: center;
}

.comment-editor-pinnedcheck > p {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #4d4d4d;
}

.comment-editor-pinnedcheck-box {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.comment-editor-label {
  font-weight: 300;
  font-size: 11px;
  line-height: 11px;
  color: #4d4d4d;
}

.comment-editor-buttons {
  margin-top: 16px;
}

.comment-editor-buttons button {
  margin-right: 16px;
  min-width: 125px;
}

.comment-editor-fileinput {
  width: 50%;
  margin-top: 8px;
}

.comment-editor-CK {
  margin-top: 7px;
}

.comment-start-discussion {
  margin-top: 20px;
  margin-left: 40%;
}

.comment-editor-post-button {
  background-color: #1689c9;
}

.comment-editor-cancel-button {
  background-color: #fff;
  color: #1689c9;
}

.comment-editor-post-button:disabled {
  background-color: #a5a5a5;
  border: 1px solid #a5a5a5;
}

.comment-preloader {
  display: inline-block;
}
