.b-assignee {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.assignee-image-size {
  width: 16px !important;
  height: 16px !important;
}

.b-assignee .btn-edit {
  margin-left: 4px;
  cursor: pointer;
  opacity: 0.5;
}
.b-assignee .btn-edit:hover {
  opacity: 1;
}
.b-assignee .btn-edit img {
  width: 12px;
  height: 12px;
}

.assignee-popover {
  background: #ffffff;
  border: 1px solid #6d9fbc;
  box-sizing: border-box;
  border-radius: 4px;
  width: 250px;
}

.assignee-popover-hidden > .popover-arrow {
  display: none;
}

.assignee-popover-hidden {
  margin-left: 37px;
  margin-top: -8px;
}

.assignee-popover-body {
  padding: 0px;
}

.assignee-list {
  overflow: auto;
  max-height: 310px;
}

.assignee-search {
  width: 92%;
  border-width: 0;
  background: #e7eef2;
  border-radius: 2px;
  box-shadow: none;
  height: 24px;
}

.assignee-search-box {
  padding: 10px;
}

.right-search-field {
  height: 24px;
  width: 8%;
  background: #e7eef2;
  border-radius: 2px;
}

.assignee-list-item {
  padding-bottom: 2px;
  border-bottom: 1px solid #afd2e7;
}
.popover-status-body {
  border-color: #6d9fbc;
  padding: 0;
}

.assignee-list-item:last-child {
  border-bottom: 0;
}

.assignee-radio-button-container {
  padding-left: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.assignee-radio-button {
  height: 10px;
  width: 10px;
}

.assignee-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #4d4d4d;
  text-align: left;
  padding: 5px 10px 5px 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
