:global(.ps-menu-icon) {
  margin-right: 0;
}

.menuSidebar {
  display: inline-block;
  padding: 16px 0;
  position: fixed;
  width: 300px;

  .top {
    position: relative;
    padding: 0 20px;
    display: flex;
    margin-bottom: 20px;
    min-height: 30px;

    .arrow {
      position: relative;
      top: 5px;
      right: -160px;
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }

  a {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
    color: #000;
    width: 100%;
  }

  :global(a.active) {
    color: #004ea6;
  }

  .blockAvatar {
    display: flex;
    align-items: center;
    margin-left: 20px;
    min-height: 60px;

    p {
      font-size: 18px;
      line-height: 22px;
      color: #1687c9;
      font-weight: 500;
    }

    .logOut {
      flex-shrink: 0;
      margin-top: 4px;
    }

    .blockPersonInfo {
      margin-left: 10px;
      min-width: 200px;
    }
  }

  .menuIcon {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }

  .menuItems {
    padding-top: 12px;
  }
}

.menuSidebarCollapsed {
  width: 66px;
  .blockAvatar {
    margin-left: 16px;
  }
  .top {
    .arrow {
      right: -3px;
    }
  }
}
