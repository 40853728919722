.container {
  height: 100vh;

  h2 {
    color: #004ea6;
    margin-bottom: 28px;
    text-align: center;
  }

  .form {
    width: 500px;
    background-color: #e8eef4;
    border: 1px solid #89add5;
    border-radius: 20px;
    padding: 40px 40px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
  }

  .center {
    text-align: center;
  }

  .errorMessage {
    margin-top: 10px;
    color: red;
  }

  :global(.form-label),
  :global(.form-check-label) {
    color: #004ea6;
  }

  :global(.form-control) {
    border: 1px solid #89add5;
  }
}
