/*@import '../../style/color.ts';*/
.b-table table {
  width: 100%;
}
.b-table th {
  background-color: #c3d6e9;
  border-top: 1px solid rgba(0, 78, 166, 0.3);
  border-bottom: 1px solid rgba(0, 78, 166, 0.3);
  padding: 16px 8px;
  font-weight: 500;
}
.b-table th:first-child {
  border-left: 1px solid rgba(0, 78, 166, 0.3);
}
.b-table th:last-child {
  border-right: 1px solid rgba(0, 78, 166, 0.3);
}
.b-table th img {
  width: 24px;
  height: 24px;
}

.b-table tbody {
  border: 1px solid rgb(213, 225, 237);
}

.b-table .cell-checkbox {
  display: inline-block;
  margin-right: 4px;
}

.b-table .cell-checkbox:first-child {
  margin-right: 8px;
}

/* .b-table .row-depth-1 td {
  background-color: #c4d7eb;
}
.b-table .row-depth-2 td {
  background-color: #d5e1ed;
}
.b-table .row-depth-3 td {
  background-color: #e8eef4;
} */

.b-table .row-depth-2 .td-checkboxes {
  padding-left: 38px;
}
.b-table .row-depth-3 .td-checkboxes {
  padding-left: 66px;
}

.b-table .row-depth-2 .td-name {
  padding-left: 40px;
}
.b-table .row-depth-3 .td-name {
  padding-left: 65px;
}

/*  ======  */

.b-table {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  border: 1px solid #6d9fbc;
  border-radius: 4px;
  margin-bottom: 24px;
}

.b-table-header {
  background-color: #e8eef4;
}
.b-table-header.table-sticky {
  position: sticky;
  top: 113px;
  z-index: 100;
}

.b-table.b-table-header .tr {
  padding: 8px 0;
}

.b-table.b-table-header .td {
  white-space: normal;
  justify-content: left;
  padding: 4px 10px;
  border-right: 1px solid #6d9fbc;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #4d4d4d;
}

.b-table.b-table-header .td:last-child {
  border-right: none;
}

.b-table .no-tasks {
  padding: 10px 16px;
  font-size: 16px;
}

.tr {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  padding: 6px 0;
  border-bottom: 1px solid #6d9fbc;
}
.tr.checked {
  background-color: #e7eef2;
}
.b-table:not(.b-table-header) .tr:hover {
  background-color: #dbe8f0;
}
.tr.project,
.tr.sprint {
  padding: 10px 0;
}

.tr.project .td {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #01629b;
}
.tr.sprint .td {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #01629b;
}

.tr:last-child {
  border-bottom: none;
}

.td {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0 10px;
  word-break: break-word;
  min-width: 0px;
  font-size: 11px;
  line-height: 13px;
}

.td .text {
  color: #01629b;
  font-weight: 500;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.green-text {
  color: #8eecc4;
}

.td-checkboxes {
  flex-grow: 0;
  flex-basis: 120px;
}

.actual-time-image {
  cursor: pointer;
  opacity: 0.5;
}

.actual-time-image:hover {
  opacity: 1;
}

.actual-time-image-size {
  height: 22px;
  width: 22px;
}

.status-row {
  padding: 5px;
  border-bottom: 1px solid #afd2e7;
}
.status-row:last-child {
  border-bottom: 0;
}

.bold-font {
  font-weight: bold;
  margin-left: 6px;
  font-size: 11px;
}
.normal-font {
  margin-left: 6px;
  font-size: 11px;
}
.form-check-status-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
}

.form-check-input.input-status {
  margin-left: -1.5em;
  width: 10px;
  height: 10px;
}
.td-priority {
  flex-grow: 1.5;
}

.status-image-size {
  width: 16px;
  height: 16px;
}

.td-status {
  flex-grow: 3;
}
.td-id {
  flex-grow: 2;
}
.td-name {
  flex-grow: 0;
  flex-basis: 300px;
  color: #01629b;
}
.td-tag {
  flex-grow: 4;
  color: #01629b;
}
.td-assigned-to {
  flex-grow: 4;
  color: #01629b;
}
.global-minimization-row {
  cursor: pointer;
}
.td-due-date {
  flex-grow: 2;
  position: relative;
}
.td-time {
  flex-grow: 3;
}
.td-message-count-team {
  flex-grow: 0;
  flex-basis: 50px;
}
.td-message-count-customer {
  flex-grow: 0;
  flex-basis: 50px;
}
.td-files-count {
  flex-grow: 0;
  flex-basis: 50px;
}

/* ===== */

.td-name-cont {
  position: relative;
  padding-left: 20px;
}
.td-name .btn-open {
  position: absolute;
  top: -3px;
  left: -6px;
  text-align: center;
  cursor: pointer;
}
.td-name .btn-open img {
  width: 24px;
  height: 20px;
  padding: 5px;
}

.tag {
  position: relative;
  display: inline-block;
  margin-right: 16px;
  padding: 3px 3px 3px 5px;
  border: 1px solid #1687c9;
  border-right: 0;
}
.tag:after {
  content: '';
  position: absolute;
  top: 2px;
  right: -8px;
  width: 15px;
  height: 15px;
  border: 1px solid #1687c9;
  border-left: 0;
  border-top: 0;
  transform: rotate(-45deg);
}
.rest-tags {
  font-weight: 700;
}

.td-time > div {
  width: 50%;
  text-align: right;
}

.td-time .actual {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.td-time .actual img {
  margin-left: 4px;
}

.b-table .preloader {
  display: block;
  margin: 10px 0 10px 15px;
}

.btn-cancel {
  cursor: pointer;
}

.btn-search {
  cursor: text;
}

.form-check-label {
  margin-left: 2px;
}

.tasks-table .form-check-input {
  margin: 0px;
  border: 2px solid #aaa;
  position: relative;
  transition: all 0.1s linear;
  box-sizing: content-box;
}

.tasks-table .cell-checkbox.task-priority-1 .form-check-input {
  border-color: rgb(130, 226, 55);
}

.tasks-table .cell-checkbox.task-priority-2 .form-check-input {
  border-color: rgb(193, 216, 61);
}

.tasks-table .cell-checkbox.task-priority-3 .form-check-input {
  border-color: rgb(255, 206, 67);
}

.tasks-table .cell-checkbox.task-priority-4 .form-check-input {
  border-color: rgb(236, 105, 35);
}

.tasks-table .cell-checkbox.task-priority-5 .form-check-input {
  border-color: rgb(216, 3, 3);
}

.btn-row {
  padding-right: 10px;
  cursor: pointer;
}

.sort-image {
  padding-left: 10px;
  cursor: pointer;
}

.min-row {
  margin: 0px 0px 20px 5px;
}

.btn-minimize {
  margin-right: 20px;
}

.sprint-btn-edit,
.sprint-btn-delete {
  margin-left: 8px;
  cursor: pointer;
  opacity: 0.5;
}

.sprint-btn-edit:hover,
.sprint-btn-delete:hover {
  opacity: 1;
}

.sprint-btn-edit img {
  display: block;
  width: 16px;
  height: 16px;
}

.sprint-btn-delete img {
  display: block;
  width: 14px;
  height: 14px;
}
