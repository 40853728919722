.popover {
  max-width: 320px;
  border: 1px solid #6d9fbc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  :global(.popover-arrow:after) {
    border-bottom-color: #e8eef4;
  }
}

.header {
  padding: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: #e8eef4;
  border-bottom: 1px solid #6d9fbc;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 12px;
    font-weight: 600;
  }

  .buttons {
    display: flex;
    gap: 6px;

    .button {
      font-size: 11px;
      padding: 3px 8px;
    }
  }
}

.form {
  padding: 8px;

  .field {
    label {
      display: block;
      font-size: 11px;
    }
  }

  .formTop {
    display: flex;
    align-items: start;
    gap: 6px;
    margin-bottom: 6px;

    .date {
      border: 1px solid rgba(85, 85, 85, 0.3);
      outline: none;
      padding: 2px 4px;
      line-height: 22px;
      border-radius: 3px;
    }

    .select {
      width: 60px;
      padding-right: 20px;
      background-position: right 6px center;
    }
  }

  .comment {
    width: 100%;
  }

  .error {
    border-color: #dc3545 !important;
  }
}
