.edit-time-multiply {
  margin-bottom: 10px;
}

.edit-time-multiply .form-check-label {
  margin-left: 0;
  font-size: 11px;
  outline: none;
}

.edit-time-multiply .edit-time__task .edit-time__task__item {
  margin-bottom: 3px;
}

.edit-time-multiply .edit-time__task {
  margin-bottom: 15px;
}

.edit-time-multiply .edit-time__date {
  margin-bottom: 5px;
}

.edit-time-multiply .time-entry-input-date {
  width: 100%;
  margin-left: 0;
}

.edit-time-multiply .custom-select__control {
  width: 100%;
}

.edit-time-multiply .edit-time__task .custom-select__menu {}