.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .window {
    position: relative;
    max-width: 1000px;
    min-width: 380px;
    background-color: #fff;
    border: 1px solid #6d9fbc;
    border-radius: 4px;

    .header {
      border-bottom: 1px solid #6d9fbc;
      background-color: #e8eef4;
      padding: 16px 40px 16px 16px;
      color: #4d4d4d;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;

      .closeButton {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }

    .content {
      padding: 18px 16px;
      border: none;
      border-radius: none;
    }
  }
}
