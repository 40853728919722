@charset "utf-8";

@import 'utils/styles/bootstrap-customize.scss';
@import '~bootstrap/scss/bootstrap';

@import 'utils/styles/fonts.scss';

body {
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  overflow-x: hidden;
  -webkit-text-size-adjust: none;
  color: #000;
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
}

#root {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 500;
}

h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}

dl,
ol,
ul {
  margin: 0;
}

p {
  margin: 0;
}

a {
  color: #01629b;
  text-decoration: none;
}

textarea {
  padding: 5px 8px;
  border: 1px solid rgba(85, 85, 85, 0.3);
  border-radius: 3px;
  outline: none;
  background-color: transparent;
  resize: none;
}

.b-left {
  text-align: left;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

/* ===== custom UI elements ===== */

.b-select > label,
.b-select-light > label,
.b-input > label,
.b-input-light > label,
.b-text-editor > label,
.form-label {
  font-weight: 300;
  font-size: 11px;
  line-height: 11px;
  color: #4d4d4d;
  margin-bottom: 0.3rem;
}

/* ===== light-select ===== */

.b-select-light .custom-select__control {
  background-color: #fff;
  border: 1px solid #6d9fbc !important;
}

/* ===== background-select ===== */

.b-select .custom-select__control {
  background-color: #e7eef2;
  border: 1px solid #e7eef2 !important;
}

/* ===== all-selects ===== */

.b-select .custom-select__control,
.b-select-light .custom-select__control {
  min-height: 32px;
  border-radius: 4px;
  padding: 0;
  box-shadow: none !important;
}

.b-select .custom-select__menu,
.b-select-light .custom-select__menu {
  margin: 0;
}

.b-select .custom-select__indicator,
.b-select-light .custom-select__indicator {
  padding: 5px;
}

.b-select .custom-select__single-value,
.b-select-light .custom-select__single-value {
  font-size: 12px;
  line-height: 14px;
  padding: 2px 0;
}

.b-select .custom-select__option,
.b-select-light .custom-select__option {
  font-size: 12px;
  line-height: 14px;
  padding: 6px 10px;
}

.b-select .custom-select__multi-value,
.b-select-light .custom-select__multi-value {
  background-color: #fff;
  border: 1px solid #1687c9;
}

.b-select .custom-select__multi-value__label,
.b-select-light .custom-select__multi-value__label {
  padding-top: 2px;
  padding-bottom: 2px;
  color: #1687c9;
}

.b-select .custom-select__multi-value__remove svg,
.b-select-light .custom-select__multi-value__remove svg {
  fill: #1687c9;
}

/* ===== input-background textarea-background ===== */

.b-input input,
.b-textarea textarea {
  background-color: #e7eef2;
}

/* ===== input-light textarea-light ===== */

.b-input-light input,
.b-textarea-light textarea {
  background-color: #fff !important;
}

/* ===== input textarea all ===== */

.b-input-light input,
.b-textarea-light textarea {
  width: 100%;
  border-radius: 4px;
  padding: 0.5rem 0.5rem;
  font-size: 12px;
  line-height: 14px;
  border: 1px solid #6d9fbc !important;
  outline: none;
}

/* ===== table ===== */

.table-wrapper {
  width: 100%;
  border: 1px solid #6d9fbc;
  border-radius: 4px;
  /* overflow: hidden; */
}

.table-custom {
  width: 100%;
}

.table-custom tr:first-child {
  background-color: #e8eef4;
}

.table-custom tr {
  border-bottom: 1px solid #6d9fbc;
}

.table-custom tr:last-child {
  border-bottom: 0;
}

.table-custom tr th {
  padding: 8px 0;
}

.table-custom tr th .title {
  border-right: 1px solid #6d9fbc;
  padding: 4px 12px;
}

.table-custom tr th.checkbox {
  width: 40px;
}

.table-custom tr th:last-child .title {
  border-right: none;
}

.table-custom tr td {
  padding: 8px 12px;
}

/* ===== React Datepicker ===== */

.react-datepicker__header {
  background-color: #e8eef4;
  border-bottom: 1px solid #6d9fbc;
}

.react-datepicker__navigation-icon::before {
  border-color: #6d9fbc;
}

.react-datepicker {
  border: 1px solid #6d9fbc;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #1689c9;
}

.react-datepicker__close-icon::after {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #6d9fbc;
  color: #6d9fbc;
  padding: 1px;
  font-size: 14px;
  line-height: 0;
}

/* ===== CKEditor customize ===== */

:root {
  --ck-color-base-border: #6d9fbc !important;
}

.ck-editor__editable {
  min-height: 100px;
}

.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
  flex-wrap: wrap !important;
}

/* ===== bootstrаp classes customize ===== */

.btn {
  font-size: 14px;
  line-height: 16px;
  padding: 0.4rem 1rem;
}

.form-control {
  font-size: 12px;
  line-height: 14px;
  border: 1px solid #6d9fbc;
  border-radius: 4px;

  &.error {
    background-color: rgba(243, 16, 16, 0.1) !important;
  }
}

.form-select {
  font-size: 14px;
  line-height: 18px;
}

.form-check {
  min-height: 0;
  padding-left: 1.8em;
}

.form-check .form-check-input {
  margin-left: -1.8em;
}

.form-check-input {
  width: 16px;
  height: 16px;
  border-color: #6d9fbc;
  margin-top: 0;
}

.tab-content {
  position: relative;
}

.nav-tabs .nav-item {
  margin-right: 8px;
}

.nav-tabs .nav-link {
  min-width: 200px;
  text-align: center;
  background-color: #e8eef4;
  color: #1687c9;
  padding: 10px 16px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 300;
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 0;
}

.nav-tabs .nav-link.active {
  font-weight: 500;
  color: #1687c9;
  background-color: #fff;
  border: 1px solid #89add5;
  border-bottom: 1px solid #fff;
  position: relative;
  bottom: -0.5px;
  z-index: 1;
}

.nav-tabs {
  border-bottom: none;
}

.popover {
  border-color: #6d9fbc;
}

.popover-status {
  border-color: #6d9fbc;
  border-width: 1px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.popover-status-hidden {
  width: 175px;
  margin-top: -8px;
  overflow: auto;
}

.popover-status-hidden > .popover-arrow {
  display: none;
}

.popover-status-body {
  border-color: #6d9fbc;
  padding: 0;
}

.bs-popover-bottom > .popover-arrow::before {
  border-bottom-color: #6d9fbc;
}

.radio-button-list {
  overflow: auto;
  max-height: 307px;
}

.b-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 11px;
  line-height: 12px;
  border: 1px solid transparent;
}
.b-status-text {
  width: calc(100% - 23px);
  text-align: center;
}
.b-status .btn-edit {
  width: 22px;
  padding: 0 3px;
  cursor: pointer;
}
.b-status .btn-edit img {
  display: block;
  width: 12px;
  height: 12px;
}
