.global-search-box {
  position: relative;
}

.global-search-box img {
  width: 12px;
}

.global-search-font {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 13px;
}

.global-search {
  background: #ffffff;
  border: 1px solid #6d9fbc;
  box-sizing: border-box;
  border-radius: 4px;
  width: 344px;
  height: 32px;
  outline: none;
  text-align: left;
  padding: 10px;
}

.global-search-placeholder {
  pointer-events: none;
  position: absolute;
  top: 10px;
  left: 10px;
}

.global-search-button {
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 10px;
}

.global-search-reset-button {
  position: absolute;
  left: 325px;
  top: 10px;
  cursor: pointer;
}

.global-search-popover {
  position: absolute;
  width: 344px;
  left: 0px;
  top: 30px;
  background: #ffffff;
  border: 1px solid #6d9fbc;
  border-radius: 4px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  z-index: 4;
  max-height: 300px;
  overflow: auto;
  overflow-x: hidden;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.global-search-row {
}

.global-search-row .link {
  display: block;
  text-decoration: none;
  color: #000;
  padding: 5px 10px;
  font-size: 13px;
  line-height: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.global-search-row .link:hover {
  background-color: #e8eef4;
}

.global-search-text-intermission {
  font-size: 16px;
  font-weight: 500;
  padding: 7px 10px;
  border-top: 1px solid #6d9fbc;
}
.global-search-text-intermission:first-child {
  border-top: 0;
}
