.projectFilters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 16px 10px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  border: 1px solid #89add5;
  border-radius: 4px;

  .leftCont {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .csvButton {
      margin-right: 12px;

      .downloadBtnIcon {
        margin-left: 10px;
        width: 13px;
      }
    }
  }

  .filters {
    display: flex;
    align-items: center;

    .filterIcon {
      display: inline-block;
      margin-right: 20px;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
