.filelist {
  margin: 10px 0;

  .item {
    display: flex;
    margin-bottom: 8px;

    .icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }

    p {
      display: inline-block;
      vertical-align: middle;
      color: #01629b;
      font-size: 12px;
      line-height: 12px;
      cursor: pointer;
    }
  }
}
