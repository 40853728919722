.container {
  text-align: center;
  margin-top: 60px;

  a:hover {
    text-decoration: underline;
  }

  .title {
    font-size: 30px;
    line-height: 30px;
    font-weight: 500;
  }

  .nav {
    margin-top: 50px;

    li {
      display: inline-block;
      margin-right: 20px;

      a {
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
        color: #4b82bf;
      }
    }
  }

  .login {
    margin-top: 50px;
    font-size: 16px;
    line-height: 16px;
  }
}
