.edit-time {
  margin-bottom: 10px;
  position: relative;
  z-index: 9999;
}

.edit-time .form-check-label {
  margin-left: 0;
  font-size: 11px;
  outline: none;
}

.edit-time .edit-time__task .edit-time__task__item {
  margin-bottom: 3px;
}

.edit-time .wrapper-time {
  display: flex;
  gap: 6px;
}

.edit-time .time-entry-comment {
  width: 100%;
}

.edit-time .edit-time__top {
  display: flex;
  gap: 6px;
  margin-bottom: 8px;
}

.edit-time .edit-time__date {
  width: 100%;
  border: 1px solid rgba(85, 85, 85, 0.3);
  outline: none;
  padding: 2px 4px;
  line-height: 22px;
  border-radius: 3px;
}

.edit-time .wrapper-time__select {
  width: 60px;
  padding-right: 20px;
  background-position: right 6px center;
}

.edit-time__wrapper-date {
  flex: 1 1;
}
