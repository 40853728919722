.changeStatus {
  .btnEdit {
    margin-right: 5px;
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    img {
      display: block;
      // width: 20px !important;
      // height: 20px !important;
    }
  }
}
