@import '../../../utils/styles/variables.scss';

.files-tab .table-wrapper {
  margin: 30px 0;
}

.files-header {
  border: 1px solid #6d9fbc;
  width: 100%;
  border-top-right-radius: $border-radius-header;
  padding: 16px;
}

.files-header .delete-button {
  opacity: 0.5;
  pointer-events: none;
}

.files-header .delete-button.active {
  opacity: 1;
  cursor: pointer;
  pointer-events: auto;
}

.file-download {
  width: 20px;
  height: 20px;
  cursor: pointer;
  box-sizing: content-box;
}

.table-custom .created {
  width: 150px;
}

.table-custom .visible {
  width: 80px;
}

.table-custom .download {
  width: 60px;
}

.table-custom .link-to-post {
  color: #01629b;
  cursor: pointer;
}
