@import '../../../../utils/styles/variables.scss';

.subscribers {
  padding: 16px;
  display: flex;
  flex-direction: row;
}

.wrapper.comm {
  border-top-right-radius: $border-radius-header;
}

.subscribers .subscriber-list {
  display: flex;
  flex-wrap: wrap;
}

.subscriber-list .add-subscriber {
  cursor: pointer;
  margin-top: 5px;
  width: 40px;
}

.subscriber-card {
  margin-top: 5px;
  width: 150px;
}

.subscriber-card-row {
  justify-content: space-between;
}

.topic {
  padding: 4px 6px 4px 6px;
  border: 1px solid #a5a5a5;
  border-radius: 4px;
  align-items: center;
  margin-right: 8px;
  height: 45px;
  margin-top: 5px;
  width: 150px;
}

.topic-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #a5a5a5;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
}
