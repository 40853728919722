.b-task-card {
  border: 1px solid #6d9fbc;
  border-radius: 4px;
  box-sizing: border-box;
}
.b-card-header {
  align-items: center;
  border-radius: 4px 4px 0 0;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  background-color: #e8eef4;
  border-bottom: 1px solid #6d9fbc;
  box-sizing: border-box;
}
.b-body-value.time {
  display: flex;
  align-items: center;
}
.b-body-value .detail {
  padding-left: 5px;
}

.b-header-name {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
}
.b-header-icons {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
.b-header-icon {
  height: 24px;
  width: 24px;
  opacity: 50%;
  cursor: pointer;
}
.b-header-icon:hover {
  opacity: 100%;
}
.b-header-icon-wastebasket {
  width: 20px;
  height: 20px;
}

.b-card-body {
  padding: 0 10px 15px 10px;
}
.b-body-param {
  padding-top: 13px;
  color: #4d4d4d;
  font-weight: 300;
  font-size: 11px;
  line-height: 11px;
}
.b-body-value {
  padding-top: 8px;
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  white-space: pre-wrap;
  word-break: break-word;
}

.b-body-description {
  font-weight: 400;
}
.b-body-description blockquote {
  margin: 0;
}
.b-body-description code {
  display: block;
}

.b-body-description > blockquote,
.b-body-description code {
  padding: 10px;
  background-color: #e8eef4;
  border-radius: 4px;
  margin: 5px 0 10px;
  word-break: break-word;
}

.file-path {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: #01629b;
  text-decoration: none;
}
.file-image {
  margin-right: 11px;
}
.file-list-item {
  padding-top: 11px;
}

@media screen and (max-width: 1400px) {
  .b-card-body {
    display: flex;
  }
  .b-body-left {
    width: 100%;
  }
  .b-body-right {
    width: 100%;
  }
}
