.b-filter-cont {
  border: 1px solid #89add5;
  border-radius: 4px;
  font-size: 12px;
  position: sticky;
  top: 60px;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 24px;
  z-index: 101;
}
.b-filter-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: nowrap;
}
.b-filter-top-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.b-filter-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}
.b-filter-grouping {
  display: flex;
  margin-top: 0.3rem;
  margin-left: 1rem;
}
.b-filter-grouping p {
  font-weight: 500;
}
.b-filter-grouping .form-check-label {
  font-weight: 300;
}
.b-filter-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.b-filter-extra {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 10px;
  gap: 10px;
}
.b-filter-extra.hidden-extra-filters {
  display: none;
}

.filter-icon {
  display: inline-block;
  margin-top: 2px;
}

.filter-icon img {
  width: 18px;
  height: 18px;
}

.b-filter-show {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.date-picker-size {
  font-size: 10px;
}

.grouping-margin {
  margin-right: 10px;
}

.b-filter-field {
  display: inline-block;
  font-size: 12px;
  font-weight: 300;
  position: relative;
}

.calendar {
  position: absolute;
  top: 10px;
  right: 10px;
  pointer-events: none;
}

.link-image {
  height: 24px;
  width: 24px;
  opacity: 50%;
  pointer-events: none;
  cursor: default;
}

.link-image.active {
  opacity: 100%;
  pointer-events: auto;
  cursor: pointer;
}

.wastebasket-image {
  height: 20px;
  width: 20px;
}

.edit-image {
  height: 24px;
  width: 24px;
}

.created-picker-calendar {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  top: 11px;
  right: 10px;
}

.b-filter-field img {
  display: inline-block;
  vertical-align: top;
  width: 11px;
}
.b-filter-field .form-control,
.b-filter-field .form-select {
  display: inline-block;
  width: 188px;
  border: 1px solid #6d9fbc;
  padding: 0.4rem 0.6rem;
}

.dropdown-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #6d9fbc;
  border-radius: 4px;
  height: 32px;
  width: 175px;
  padding: 10px;
  justify-content: space-between;
  cursor: default;
  outline: none;
}

.dropdown-text {
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.b-filter-toggle {
  width: fit-content;
}
