.tablewrapper {
  border: 1px solid #6d9fbc;
  border-radius: 4px;
  margin-top: 20px;
}

.tablewrapper_empty {
  margin-top: 16px;
}

.tab-header {
  border-bottom: 1px solid #6d9fbc;
  padding: 8px;
  height: 48px;
  border-style: solid;
  border-collapse: collapse;
  height: 48px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #e8eef4;
  border-radius: 4px 4px 0 0;
}

.tab-table {
  caption-side: bottom;
  border-collapse: collapse;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.checkbox {
  width: 5%;
}

.headertab {
  border-right: 1px solid #6d9fbc;
  cursor: pointer;
}

.checkbox.headertab {
  cursor: default;
  border-right: none;
}

.datetab {
  padding-left: 10px;
  width: 60%;
}

td.datetab,
td.usertab {
  text-decoration: underline;
  color: #01629b;
  font-weight: 500;
  cursor: pointer;
}

td.datetab.datetab_disabled {
  text-decoration: none;
  color: #000;
  cursor: default;
}

.created {
  padding-left: 10px;
  width: 60%;
}

.usertab {
  padding-left: 10px;
  width: 60%;
}

.comment {
  padding-left: 10px;
  width: 120%;
}

.hours {
  cursor: pointer;
  width: 60%;
  padding-left: 10px;
}

.timerow {
  height: auto;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #6d9fbc;
  padding: 8px;
}

.timerow:last-child {
  border-radius: 0px 0px 4px 4px;
  border: none;
}

.icon-sort {
  margin-left: 5px;
}

.empty-rows {
  margin-top: 10px;
}