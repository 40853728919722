.fileItem {
  padding: 5px;

  .icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  p {
    display: inline-block;
    vertical-align: middle;
    color: #4d4d4d;
    font-size: 12px;
    line-height: 12px;
  }

  .deleteButton {
    display: inline-block;
    margin-left: 6px;
    background: transparent;
    cursor: pointer;
  }
}
