.b-kanban {
  height: 100%;
  width: 100%;

  white-space: nowrap;
}
.b-kanban-main {
  display: flex;
}
.b-kanban-footer {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 500px;
}
.header-status:last-child,
.b-kanban-list:last-child {
  margin-right: 0;
}
.b-kanban-headers {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  position: sticky;
  top: 114px;
  z-index: 10;
}
.header-status {
  margin-right: 10px;
  width: 100%;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  padding: 12px 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-bottom: 8px;
}
.b-kanban-wrapper .scroll-btn {
  position: absolute;
  top: 0;
  width: 40px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 5;
  cursor: pointer;
}

.b-kanban-wrapper .scroll-btn:hover {
  background-color: rgba(22, 137, 201, 0.15);
}

.b-kanban-wrapper .scroll-btn.left {
  left: 0;
}

.b-kanban-wrapper .scroll-btn.right {
  right: 0;
}

.b-kanban-wrapper .scroll-btn img {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 5px);
  right: 0;
  width: 12px;
  height: 18px;
}
