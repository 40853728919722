.activityTable {
  margin-top: 20px;

  table {
    border-collapse: collapse;
    display: flex;
    flex-direction: column;

    thead {
      tr {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        background-color: #e8eef4;
        height: 44px;
        border: 1px solid #6d9fbc;
        padding: 0 15px;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        border-radius: 4px 4px 0 0;
      }
      th {
        display: flex;
        align-items: center;
        span {
          color: grey;
        }
        div {
          width: 100%;
          img {
            margin-left: 5px;
          }
        }
      }
      th:not(:last-of-type) {
        div {
          border-right: 1px solid #6d9fbc;
        }
      }
      .sortableColumn {
        cursor: pointer;
        span {
          color: #000;
        }
      }
    }

    tbody {
      tr {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #6d9fbc;
        border-left: 1px solid #6d9fbc;
        border-right: 1px solid #6d9fbc;
        padding: 0 15px;
      }
      tr:first-of-type {
        border-top: 0;
      }
    }

    td,
    th {
      padding: 10px 0px 10px 10px;
    }

    td:nth-child(1),
    th:nth-child(1) {
      width: 15%;
    }

    td:nth-child(2),
    th:nth-child(2) {
      width: 15%;
    }

    td:nth-child(3),
    th:nth-child(3) {
      width: 70%;
    }
  }
}
